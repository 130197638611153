<template>
    <div>
        <div v-if="authUserPermission['equipment-code-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col">
                                <h3 class="mb-0">{{ tt('equipment_code') }}</h3>
                            </div>
                            <div class="col-5 text-right">
                                <div class="form-group">
                                    <base-button size="sm" type="default" @click="formSearch.show = true">{{ tt('filter') }}</base-button>
                                    <!-- <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('search_equipment_code')" v-on:keyup="filter"/>                             -->
                                </div>
                            </div>
                            <!-- <div class="col text-right">
                                <base-button size="sm" type="default" @click="create" v-if="authUserPermission['equipment-code-create']">{{ tt('add_new') }}</base-button>
                            </div> -->
                        </div>
                    </div>
                    <div>
                        <el-table height="500px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('company_code')" :prop="tt('company_code')" min-width="180px" sortable>
                                <template v-slot="{row}">
                                    {{row.company_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('plant_code')" :prop="tt('plant_code')" min-width="180px" sortable>
                                <template v-slot="{row}">
                                    {{row.plant_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('equipment_code')" :prop="tt('equipment_code')" min-width="180px" sortable>
                                <template v-slot="{row}">
                                    {{row.equipment_code}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('equipment_name')" :prop="tt('equipment_name')" min-width="180px" sortable>
                                <template v-slot="{row}">
                                    {{row.equipment_name}}
                                </template>
                            </el-table-column>
                            <!-- <el-table-column :label="tt('description')" :prop="tt('description')" min-width="180px" sortable>
                                <template v-slot="{row}">
                                    {{row.equipment_description}}
                                </template>
                            </el-table-column> -->
                            <el-table-column :label="tt('type')" :prop="tt('type')" min-width="180px" sortable>
                                <template v-slot="{row}">
                                    {{row.type}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('serial_number')" :prop="tt('serial_number')" min-width="180px" sortable>
                                <template v-slot="{row}">
                                    {{row.serial_number}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('manufacturer')" :prop="tt('manufacturer')" min-width="180px" sortable>
                                <template v-slot="{row}">
                                    {{row.manufacturer}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('additional_data')" :prop="tt('additional_data')" min-width="185px" sortable>
                                <template v-slot="{row}">
                                    {{row.additional_data}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['equipment-code-update'] || authUserPermission['equipment-code-delete']">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row.id}" v-if="authUserPermission['equipment-code-update']">{{ tt('edit') }}</el-dropdown-item>
                                            <!-- <el-dropdown-item :command="{action:'remove', data:row.id}" v-if="authUserPermission['equipment-code-delete']">{{ tt('delete') }}</el-dropdown-item> -->                                        
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <skeleton-loading v-else/>
                    </div>
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="form.show">
                    <template slot="header">
                        <h5 class="modal-title">{{form.title}}</h5>
                    </template>
                    <div>
                        <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert>
                        <label class="form-control-label">{{ tt('equipment_code') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('equipment_code')" :placeholder="tt('equipment_code')" v-model="equipmentCode.equipment_code" disabled></base-input>

                        <label class="form-control-label">{{ tt('equipment_name') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('equipment_name')" :placeholder="tt('equipment_name')" v-model="equipmentCode.equipment_name" disabled></base-input>

                        <!-- <label class="form-control-label">{{ tt('equipment_description') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('equipment_description')" :placeholder="tt('equipment_description')" v-model="equipmentCode.equipment_description" rules="required"></base-input> -->

                        <label class="form-control-label">{{ tt('company_code') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('company_code')">
                            <el-select class="select-danger" v-model="equipmentCode.company_code" :placeholder="tt('choose_company')" disabled>
                                <el-option class="select-danger" :value="cc['company_code']" :label="cc['company_code'] + ' - ' + cc['company_description']" :key="cc['company_code'] + ' - ' + cc['company_description']" v-for="cc in  companyCode">{{ cc['company_code'] }} - {{ cc['company_description'] }}</el-option>
                            </el-select>
                        </base-input>

                        <label class="form-control-label">{{ tt('plant_code') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('plant_code')">
                            <el-select class="select-danger" v-model="equipmentCode.plant_code" :placeholder="tt('choose_plant')" disabled>
                                <el-option class="select-danger" :value="pc['plant_code']" :label="pc['plant_code'] + ' - ' + pc['description']" :key="pc['plant_code'] + ' - ' + pc['description']" v-for="pc in  plantCode">{{ pc['plant_code'] }} - {{ pc['description'] }}</el-option>
                            </el-select>
                        </base-input>

                        <label class="form-control-label">{{ tt('type') }}</label>
                        <base-input :name="tt('type')" :placeholder="tt('type')" v-model="equipmentCode.type" rules="max:100"></base-input>

                        <label class="form-control-label">{{ tt('serial_number') }}</label>
                        <base-input :name="tt('serial_number')" :placeholder="tt('serial_number')" v-model="equipmentCode.serial_number" rules="max:32"></base-input>

                        <label class="form-control-label">{{ tt('manufacturer') }}</label>
                        <base-input :name="tt('manufacturer')" :placeholder="tt('manufacturer')" v-model="equipmentCode.manufacturer" rules="max:100"></base-input>

                        <label class="form-control-label">{{ tt('additional_data') }}</label>
                        <base-input :name="tt('additional_data')" :placeholder="tt('additional_data')" v-model="equipmentCode.additional_data" rules="max:100"></base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="form.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="save" :disabled="btnSave.onLoading || invalid">
                            <span v-if="btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="form.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>

            <!-- SEARCH EQUIPMENT -->
            <modal :show.sync="formSearch.show">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('filter') }}</h5>
                </template>
                <div>
                    <label class="form-control-label">{{ tt('company_code') }}</label>
                    <base-input>
                        <el-select v-model="searchEquipment.company_code" :placeholder="tt('company_code')" :remote-method="querySearchCompany" :loading="loading" filterable remote reserve-keyword clearable>
                            <el-option v-for="cp in companyCode" :key="cp.company_code" :label="cp.company_code +' - '+ cp.company_description" :value="cp.company_code"></el-option>
                        </el-select>
                    </base-input>

                    <label class="form-control-label">{{ tt('plant_code') }}</label>
                    <base-input>
                        <el-select v-model="searchEquipment.plant_code" :placeholder="tt('plant_code')" :remote-method="querySearchPlant" :loading="loading" filterable remote reserve-keyword clearable>
                            <el-option v-for="pl in plantCode" :key="pl.plant_code" :label="pl.plant_code +' - '+ pl.description" :value="pl.plant_code"></el-option>
                        </el-select>
                    </base-input>

                    <label class="form-control-label">{{ tt('equipment_code') }}</label>
                    <base-input>
                        <el-select v-model="searchEquipment.equipment_code" :placeholder="tt('equipment_code')" :remote-method="querySearchEquipment" :loading="loading" filterable remote reserve-keyword clearable>
                            <el-option v-for="ep in equipment_code" :key="ep.equipment_code" :label="ep.equipment_code +' - '+ ep.equipment_name" :value="ep.equipment_code"></el-option>
                        </el-select>
                    </base-input>

                    <label class="form-control-label">{{ tt('equipment_name') }}</label>
                    <base-input :name="tt('equipment_name')" :placeholder="tt('equipment_name')" v-model="searchEquipment.equipment_name"/>

                    <label class="form-control-label">{{ tt('type') }}</label>
                    <base-input :name="tt('type')" :placeholder="tt('type')" v-model="searchEquipment.type"/>

                    <label class="form-control-label">{{ tt('serial_number') }}</label>
                    <base-input :name="tt('serial_number')" :placeholder="tt('serial_number')" v-model="searchEquipment.serial_number"/>

                    <label class="form-control-label">{{ tt('sm_manufacturer') }}</label>
                    <base-input :name="tt('manufacturer')" :placeholder="tt('sm_manufacturer')" v-model="searchEquipment.manufacturer"/>

                    <label class="form-control-label">{{ tt('additional_data') }}</label>
                    <base-input :name="tt('additional_data')" :placeholder="tt('additional_data')" v-model="searchEquipment.additional_data"/>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="formSearch.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="filtering" :disabled="btnSearch.onLoading">
                        <span v-if="btnSearch.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            {{tt('search')}}
                        </span>
                    </base-button>
                </template>
            </modal>
            </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import companyCode from '@/services/master/companyCode.service';
    import plantCode from '@/services/master/plantCode.service';

    export default {        
        data() {
            return {                 
                onLoad: true,      
                loadTimeout: null,  
                btnSave: {
                    onLoading: false
                },                    
                form: {
                    add: true,
                    title: this.tt('add_equipment_code'),
                    show: false
                }, 
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },  
                formSearch: {
                    show: false
                },  
                btnSearch: {
                    onLoading: false
                }, 
                searchEquipment: {
                    company_code: '',
                    plant_code: '',
                    equipment_code: '',
                    equipment_name: '',
                    type: '',
                    serial_number: '',
                    manufacturer: '',
                    additional_data: '',
                },
                errorMessage: null,   
                equipmentCode: {},
                equipment_code: {},
                companyCode: {},
                plantCode: {},
                loading:false,
                selectSearch: {
                    loadTimeout: null
                },
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, equipmentCode.get(this.table.page.current_page, this.$route.query)).onSuccess(function(response) {  
                    context.table.total = response.data.data.data.total;
                    context.table.data = response.data.data.data.data;  
                    context.table.page  = response.data.data.data;  
                    context.onLoad = false;          
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            filtering() {
                let context = this;
                context.btnSearch.onLoading = true;
                this.$router.push({ path: 'equipment', query: this.searchEquipment });

                Api(context, equipmentCode.get(this.table.page.current_page, {company_code: context.searchEquipment.company_code, plant_code: context.searchEquipment.plant_code, equipment_code: context.searchEquipment.equipment_code, equipment_name: context.searchEquipment.equipment_name, type: context.searchEquipment.type, serial_number: context.searchEquipment.serial_number, manufacturer: context.searchEquipment.manufacturer, additional_data: context.searchEquipment.additional_data })).onSuccess(function(response) {    
                    context.table.data = response.data.data.data.data;
                    context.table.total = response.data.data.data.data.length;
                    context.table.page  = 1
                    context.onLoad = false; 
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                }).onFinish(function() {
                    context.onLoad = false;
                    context.btnSearch.onLoading = false;
                    context.formSearch.show = false;
                }).call()
            },
            filter() {
                this.onLoad = true;
                this.table.page.current_page = 1;
                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.errorMessage = null;
                this.form.add = true;
                this.form.title = this.tt('add_equipment_code');
                this.form.show = true;
                this.equipmentCode = {};
            },            
            edit(id) {
                this.errorMessage = null;
                let context = this;               
                Api(context, equipmentCode.show(id)).onSuccess(function(response) {
                    context.equipmentCode = response.data.data;
                    context.form.add = false;
                    context.form.title = context.tt('edit_equipment_code');
                    context.form.show = true;                          
                })
                .call()        
            },
            save() {                      
                this.btnSave.onLoading = true;
                let api = null;
                let context = this;

                if (this.form.add) {
                    api = Api(context, equipmentCode.create(this.equipmentCode));
                } else {{
                    api = Api(context, equipmentCode.update(this.equipmentCode.id, this.equipmentCode));
                }}
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.show = false;
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                }).onFinish(function() {
                    context.btnSave.onLoading = false;   
                })
                .call();
            },
            remove(id) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, equipmentCode.delete(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
            querySearchCompany(query) {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, companyCode.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.companyCode = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.companyCode = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchPlant(query) {
                if (query.length > 1) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, plantCode.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.plantCode = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.plantCode = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
            querySearchEquipment(query) {
                if (query.length > 3) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, equipmentCode.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.equipment_code = response.data.data.data.data;    
                            context.loading = false;                                           
                        }).onError(function(error) {
                            context.equipment_code = [];
                            context.loading = false;
                        }).call();             
                    }, 200)
                }
            },
        }   
    };
</script>
<style></style>
