import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('Plant?page=' + page, {params});
    }, 
    show(id) {
        return Service().get('Plant/show/'+id);
    },    
    create(params) {
        return Service().post('Plant/create', params);
    },
    update(id, params) {
        return Service().post('Plant/update/'+id, params);
    },
    delete(id) {
        return Service().post('Plant/delete/'+id);
    },
    companyPlant(params) {
        return Service().get('Plant-company', {params});
    },
    materialPlantException(materialNumber, params) {
        return Service().get('Plant/materialPlantException/' + materialNumber, {params});
    }
}